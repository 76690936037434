import React from 'react';
import Works from "@/components/Works/Works";
import Footer from "@/components/Footer/Footer";
import Header from "@components/Header/Header";
import { Helmet } from 'react-helmet'; 

function WorkPage() {
  return (
    <div className="WorkPage">
      <Helmet>
        <title>My Works | Chirag's Portfolio</title>
        <meta name="description" content="Explore the projects and works created by Chirag, a dedicated full-stack web developer." />
      
      </Helmet>
      <Header />
      <main>
        <Works />
      </main>
      <Footer />
    </div>
  );
}

export default WorkPage;
