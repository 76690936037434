import React, { useState, useRef } from 'react';
import emailjs from 'emailjs-com';
import './Contact.css';

const Contact = ({ onClose }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState('');
  const form = useRef();

  const sendEmail = async (e) => {
    e.preventDefault();
    setStatus('Sending...');

    try {
      const result = await emailjs.sendForm(
        'service_u5ikspm', 
        'template_yaudoa8', 
        form.current, 
        'A_1lc9ivZuBi_1aSU'
      );
      console.log(result.text);
      setStatus('Message sent successfully!');
      setName('');
      setEmail('');
      setMessage('');
    } catch (error) {
      console.error('Failed to send message:', error);
      setStatus('Failed to send message. Please try again.');
    }
  };

  return (
    <div className="contact-overlay">
      <div className="contact-container">
        <button className="close-btn" onClick={onClose}>&times;</button>
        <div className="contact-content">
          <h1 className="contact-title">Get in Touch</h1>
          <p className="contact-subtitle">I'd love to hear from you! Drop me a message and I'll get back to you as soon as possible.</p>
          
          <form ref={form} onSubmit={sendEmail} className="contact-form">
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                aria-label="Your Name"
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                aria-label="Your Email"
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                title="Please enter a valid email address."
              />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                name="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
                aria-label="Your Message"
                minlength="10"
                placeholder="Enter at least 10 characters"
              ></textarea>
            </div>
            <button type="submit" className="submit-btn" aria-label="Send Message">Send Message</button>
          </form>
          
          {/* Status message with color indication */}
          {status && (
            <p className={`status-message ${status.includes('successfully') ? 'success' : 'error'}`}>
              {status}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Contact;
