import React from 'react';
import About from "@/components/About/About";
import Footer from "@/components/Footer/Footer";
import Header from "@components/Header/Header";
import { Helmet } from 'react-helmet';

function AboutPage() {
  return (
    <div className="AboutPage">
      <Helmet>
        <title>About Chirag</title>
        <meta name="description" content="Learn more about Chirag, a full-stack web developer passionate about cloud technologies." />
        {/* Add other meta tags as needed */}
      </Helmet>
      <Header />
      <main>
        <About />
      </main>
      <Footer />
    </div>
  );
}

export default AboutPage;
