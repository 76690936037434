import React from 'react';
import { motion } from 'framer-motion';
import narasimha from "../../assets/images/narasimha.svg";
import eventifyconnect from "../../assets/images/eventifyconnect.png";
import './Works.css';

const fadeInUpVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.6 } }
};

const staggerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2
    }
  }
};

function Works() {
  return (
    <motion.div 
      className="works-container"
      initial="hidden"
      animate="visible"
      variants={staggerVariants}
    >
      {/* Narasimha Enterprises Project */}
      <motion.article className="project" variants={fadeInUpVariants}>
        <div className="project-content">
          <h2 className="project-title">Narasimha Enterprises Hospitality</h2>
          <p className="project-subtitle">A comprehensive hospitality management website | Freelance Project</p>
          <motion.div 
            className="image-wrapper"
            whileHover={{ scale: 1.02 }}
            transition={{ type: "spring", stiffness: 300 }}
          >
            <a 
              href="https://narasimhaenterpriseshospitality.in/" 
              target="_blank" 
              rel="noopener noreferrer"
              aria-label="Visit Narasimha Enterprises website"
            >
              <img 
                src={narasimha} 
                alt="Narasimha Enterprises Hospitality website screenshot" 
                className="project-image" 
                loading="lazy"
              />
            </a>
          </motion.div>
          <div className="description">
            <p className="description-text">
              Led the development of a comprehensive website for Narasimha Enterprises Hospitality as a freelance project. The website serves as a digital showcase for their premium hospitality services, featuring:
              <br /><br />
              • Modern, responsive design optimized for all devices
              <br />
              • Detailed service catalog highlighting their hospitality offerings
              <br />
              • Professional team portfolio showcasing their experienced staff
              <br />
              • Interactive contact forms and location information
              <br /><br />
              Technologies used: React.js, CSS3, Responsive Design
            </p>
            <motion.a 
              href="https://narasimhaenterpriseshospitality.in/" 
              target="_blank" 
              rel="noopener noreferrer"
              className="view-project-btn"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              aria-label="View Narasimha Enterprises Hospitality project"
            >
              View Project
            </motion.a>
          </div>
        </div>
      </motion.article>

      {/* EventifyConnect Project */}
      <motion.article className="project" variants={fadeInUpVariants}>
        <div className="project-content">
          <h2 className="project-title">EventifyConnect</h2>
          <p className="project-subtitle">An event/venue booking platform | Internship Project</p>
          <motion.div 
            className="image-wrapper"
            whileHover={{ scale: 1.02 }}
            transition={{ type: "spring", stiffness: 300 }}
          >
            <a 
              href="https://eventifyconnect.com/" 
              target="_blank" 
              rel="noopener noreferrer"
              aria-label="Visit EventifyConnect website"
            >
              <img 
                src={eventifyconnect} 
                alt="Eventify Connect platform screenshot" 
                className="project-image" 
                loading="lazy"
              />
            </a>
          </motion.div>
          <div className="description">
            <p className="description-text">
              Developed EventifyConnect during an enriching internship experience, creating a sophisticated event management platform that streamlines the event planning process. Key features include:
              <br /><br />
              • Intuitive venue discovery and booking system
              <br />
              • Advanced event planning tools with customizable templates
              <br />
              • Real-time availability checking and instant booking
              <br />
              • Comprehensive guest management system
              <br />
              • Analytics dashboard for venue partners
              <br /><br />
              Technologies used: React.js, Node.js, MongoDB, Firebase.
            </p>
            <motion.a 
              href="https://eventifyconnect.com/" 
              target="_blank" 
              rel="noopener noreferrer"
              className="view-project-btn"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              aria-label="View EventifyConnect project"
            >
              View Project
            </motion.a>
          </div>
        </div>
      </motion.article>

      {/* Structured Data - Updated with project types */}
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "ItemList",
            "itemListElement": [
              {
                "@type": "CreativeWork",
                "position": 1,
                "url": "https://narasimhaenterpriseshospitality.in/",
                "name": "Narasimha Enterprises Hospitality",
                "description": "A comprehensive hospitality management website developed as a freelance project.",
                "creator": {
                  "@type": "Person",
                  "name": "Chirag A K"
                }
              },
              {
                "@type": "CreativeWork",
                "position": 2,
                "url": "https://eventifyconnect.com/",
                "name": "EventifyConnect",
                "description": "An event management platform developed during internship, offering comprehensive event planning and venue booking solutions.",
                "creator": {
                  "@type": "Person",
                  "name": "Chirag A K"
                }
              }
            ]
          }
        `}
      </script>
    </motion.div>
  );
}

export default Works;