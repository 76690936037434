import React, { useEffect, useRef } from 'react';
import './About.css';
import profileImage from '../../assets/images/profile.jpg';
import awsDeveloperLogo from '../../assets/images/awsdeveloper.png';
import awsCloudPractitionerLogo from '../../assets/images/awspractioner.png';
import ranpiseLogo from '../../assets/images/ranpise-logo.jpg';
import IconCloud from "@/components/magicui/icon-cloud";

// Club logos array remains the same
const clubLogos = [
  {
    name: "Newton School Coding Club",
    image: require("../../assets/images/nscc-club.svg").default,
    link: "https://www.newtonschool.co/"
  },
  {
    name: "Sahyadri Open Source Community",
    image: require("../../assets/images/sosc-club.svg").default,
    link: "https://www.sosc.org.in/"
  },
  {
    name: "CoE Cybersecurity Sahyadri",
    image: require("../../assets/images/coe-club.svg").default,
    link: "https://coe-dfics-scem.vercel.app/"
  },
  {
    name: "Mozilla Club",
    image: require("../../assets/images/mozilla-club.svg").default,
    link: "https://mozilla.org"
  },
  {
    name: "National Service Scheme",
    image: require("../../assets/images/nss-club.svg").default,
    link: "https://nss.gov.in/"
  }
];

const slugs = [
  "figma", "html5", "css3", "javascript", "react", "django", "java", "amazonaws",
  "visualstudiocode", "git", "vercel", "express", "androidstudio", "redux",
  "python", "mysql", "mongodb", "firebase", "sass"
];

const About = () => {
  const profileImageRef = useRef(null);
  const aboutTextRef = useRef(null);
  const certificationRefs = useRef([]);
  const skillsRef = useRef(null);
  const experienceRef = useRef(null);
  const clubsRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('animate-in');
          }
        });
      },
      { threshold: 0.1 }
    );

    const refs = [
      profileImageRef.current,
      aboutTextRef.current,
      skillsRef.current,
      experienceRef.current,
      clubsRef.current,
      ...certificationRefs.current
    ].filter(Boolean);

    refs.forEach(ref => observer.observe(ref));

    return () => observer.disconnect();
  }, []);

  // Duplicate the items to create seamless scrolling
  const duplicatedLogos = [...clubLogos, ...clubLogos];

  return (
    <div className="about-container">
      {/* SEO Meta Tags */}
      <meta name="description" content="Chirag A K - Full-stack web developer and cloud enthusiast with AWS certifications. Passionate about cloud technologies and development." />
      <meta name="keywords" content="Chirag A K, full-stack developer, AWS certified, cloud computing, web development, React, Django, portfolio" />

      <section className="about-section">
        <h2 className="section-title animate-title">About Me</h2>
        <div className="about-content">
          <img
            ref={profileImageRef}
            src={profileImage}
            alt="Chirag A K - Full-stack Developer"
            className="profile-image"
            loading="lazy"
          />
          <div ref={aboutTextRef} className="about-text">
            <p>Hey there! I'm Chirag – a full-stack developer, AWS-certified cloud explorer, and all-around tech enthusiast with a background in Information Science from Sahyadri College. I'm passionate about creating immersive, dynamic web experiences and love staying on the cutting edge of tech to keep my skills sharp and my projects fresh.</p>
            <p>For me, coding is best enjoyed with a team – there's nothing like bouncing ideas around to bring out the best in a project. My curiosity keeps me constantly exploring, whether I'm leveling up my cloud expertise or diving into the latest tech trends. And when I'm not deep in code, you'll probably find me kicking back with a game of FIFA, recharging for the next big challenge.</p>
            <p>From cloud certifications to passion projects, I'm always up for learning, building, and making an impact in tech. Let's make something awesome!</p>
          </div>
        </div>
      </section>

      {/* Rest of the component remains the same */}
      <section className="work-experience-section">
        <h2 className="section-title animate-title">Work Experience</h2>
        <div ref={experienceRef} className="work-experience">
          <div className="experience">
            <a href="https://ranpisedigitech.com/" target="_blank" rel="noopener noreferrer">
              <img src={ranpiseLogo} alt="Ranpise DigiTech Solutions" className="experience-logo" />
            </a>
            <div className="experience-details">
              <h3><strong>Ranpise DigiTech Solutions Pvt Ltd</strong></h3>
              <p>Mar 2024 - May 2024</p>
              <p>Full-stack Web Development Intern</p>
              <p>A remote internship in web development, managing end-to-end tasks including requirement gathering, analysis, design, coding, testing, and documentation.</p>
              <p>Gained proficiency in full-stack web development through a 3-month internship project.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="skills-section">
        <h2 className="section-title animate-title">Skills</h2>
        <div ref={skillsRef} className="skills-content">
          <div className="icon-cloud-container">
            <IconCloud iconSlugs={slugs} />
          </div>
          <div className="skills-list">
            <p>Front-End: HTML, CSS, JavaScript, React</p>
            <p>Cloud: AWS services</p>
            <p>Tools: Git, VS Code, Android Studio, Vercel</p>
            <p>Design: Figma</p>
          </div>
        </div>
      </section>

      <section className="certification-section">
        <h2 className="section-title animate-title">Certifications</h2>
        <div className="certifications">
          <div key={0} ref={(el) => (certificationRefs.current[0] = el)} className="certification">
            <a href="https://cp.certmetrics.com/amazon/en/public/verify/credential/f4e0b841f0d2457fae95fbee9a604660" target="_blank" rel="noopener noreferrer">
              <img
                src={awsDeveloperLogo}
                alt="AWS Certified Developer – Associate"
                loading="lazy"
              />
            </a>
            <h3>AWS Certified Developer – Associate</h3>
            <p>Proficiency in developing, deploying, and debugging cloud-based applications using AWS.</p>
          </div>
          <div key={1} ref={(el) => (certificationRefs.current[1] = el)} className="certification">
            <a href="https://cp.certmetrics.com/amazon/en/public/verify/credential/a8f7b3d3ec90492fb1ec1f93d2182a5e" target="_blank" rel="noopener noreferrer">
              <img
                src={awsCloudPractitionerLogo}
                alt="AWS Certified Cloud Practitioner"
                loading="lazy"
              />
            </a>
            <h3>AWS Certified Cloud Practitioner</h3>
            <p>Comprehensive understanding of AWS Cloud concepts, services, security, architecture, pricing, and support.</p>
          </div>
        </div>
      </section>

      <section className="clubs-section">
        <h2 className="section-title animate-title">Clubs & Communities</h2>
        <div ref={clubsRef} className="carousel-container">
          <div className="carousel-track">
            {duplicatedLogos.map((club, index) => (
              <a
                key={`${club.name}-${index}`}
                href={club.link}
                target="_blank"
                rel="noopener noreferrer"
                className="carousel-item"
              >
                <div className="carousel-logo-container">
                  <img
                    src={club.image}
                    alt={club.name}
                    className="carousel-logo"
                    loading="lazy"
                  />
                </div>
                <h3 className="carousel-club-name">{club.name}</h3>
              </a>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;