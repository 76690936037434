import React from 'react';
import './Footer.css';
import { FaLinkedin, FaGithub, FaInstagram } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="footer" aria-label="Site Footer">
      <div className="footer-content">
        <div className="footer-info">
          <a href="https://github.com/chiragak" title="GitHub Profile" aria-label="GitHub" className="social-link">
            <FaGithub />
          </a>
          <a href="https://www.linkedin.com/in/chiragak" title="LinkedIn Profile" aria-label="LinkedIn" className="social-link">
            <FaLinkedin />
          </a>
          <a href="https://www.instagram.com/_chiragak/" title="Instagram Profile" aria-label="Instagram" className="social-link">
            <FaInstagram />
          </a>
        </div>
        <div className="footer-text">
          Chirag<span className="dots">..</span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;