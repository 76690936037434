import React from 'react';
import Home from "@components/Home/Home";
import Header from "@components/Header/Header";

function HomePage() {
  return (
    <div className="HomePage">
      <Header />
      <main>
        <Home />
      </main>
    </div>
  );
}

export default HomePage;
